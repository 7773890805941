const html = document.querySelector("html");
const toggle = document.querySelector("#theme-changer");
const label = document.querySelector("#theme-changer-label");
const icon = label.querySelector("i");

toggle.addEventListener("change", (evt) => changeTheme(evt.target.checked));

const changeTheme = (state) => {
    if (state) {
        icon.classList.remove("ti-sun");
        icon.classList.remove("text-warning");
        icon.classList.add("ti-moon");
        icon.classList.add("text-accent");
        html.dataset.theme = "halloween";
    } else {
        icon.classList.add("ti-sun");
        icon.classList.add("text-warning");
        icon.classList.remove("text-accent");
        icon.classList.remove("ti-moon");
        html.dataset.theme = "bumblebee";
    }
    sessionStorage.theme_changer = state;
};

if (sessionStorage.hasOwnProperty("theme_changer")) {
    toggle.checked = sessionStorage.theme_changer === "true";
    changeTheme(toggle.checked);
} else {
    sessionStorage.setItem("theme_change", false);
}
